(function(){

	var button = document.getElementById('cn-button'),
    wrapper = document.getElementById('cn-wrapper');

    //open and close menu when the button is clicked
	var open = false;
	if(button){
		button.addEventListener('click', handler, false);
	}
	

	function handler(){
	  if(!open){
		this.innerHTML = "Close";
		wrapper.classList.add('opened-nav')
	   
	  }
	  else{
		this.innerHTML = "Resources";
		wrapper.classList.remove('opened')
		
	  }
	  open = !open;
	}
	function closeWrapper(){
		wrapper.classList.remove('opened')
	}

})();
